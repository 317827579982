import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';

import Modal from '@components/molecules/Modal/Modal';
import TextareaWithCounter from '@components/molecules/TextareaWithCounter/TextareaWithCounter';
import CreateInsightButton from '@components/molecules/CreateInsightButton/CreateInsightButton';

const InsightModal = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const createBtnRef = useRef(null);
  const MAX_TEXT_LENGTH = 500;

  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    createBtnRef.current.focus();
  };

  const addInsight = (e) => {
    e.preventDefault();
    dispatch(actions.createInsight(text));
    close();
  };

  const handleTextChange = (currentText) => {
    setText(currentText);
    currentText.length > MAX_TEXT_LENGTH
      ? setDisabled(true)
      : setDisabled(false);
  };

  return (
    <>
      <CreateInsightButton ref={createBtnRef} onClick={open} />

      <Modal isOpen={isOpen} onDismiss={close}>
        <div className="mars-text--center">
          <h2 className="mars-gamma">Write your insight</h2>
          <p className="mars-margin-bottom--sm">
            Insights should be acceptable, understandable and meaninful. Write
            your own to add to the collection here.
          </p>
        </div>
        <form onSubmit={(e) => addInsight(e)}>
          <div className="mars-form-row">
            <TextareaWithCounter
              onTextChange={handleTextChange}
              maxTextLength={MAX_TEXT_LENGTH}
            />
          </div>
          <div className="mars-form-row">
            <button disabled={disabled} className="mars-button">
              Done
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default InsightModal;
