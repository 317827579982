import React from 'react';
import PropTypes from 'prop-types';
import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './CreateInsightButton.scss';

const CreateInsightButton = React.forwardRef((props, ref) => {
  const handleClick = (e) => {
    if (typeof props.onClick === `function`) {
      props.onClick();
    }
  };

  return (
    <div>
      <Card className="mars-card--height-auto">
        <button
          className="mars-create-insight-button mars-padding-y--md"
          onClick={handleClick}
          ref={ref}>
          <MarsIcon
            icon="insight"
            className="mars-create-insight-button__icon"
          />
          <div className="mars-button mars-button--wide mars-button--secondary">
            <span className="mars-button__text">Create an insight</span>
            <MarsIcon icon="add" />
          </div>
        </button>
      </Card>
    </div>
  );
});

export default CreateInsightButton;

CreateInsightButton.propTypes = {
  onClick: PropTypes.func
};
