import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import Card from '@components/atoms/Card/Card';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './SavedInsightStarterPoint.scss';

const SavedSavedInsightStarterPoint = ({ id, momentTitle, text }) => {
  const dispatch = useDispatch();
  const focusOnModalCloseRef = useRef(null);

  const onActionBtnClick = (e) => {
    dispatch(
      actions.openRemoveFromCollection(
        `insightStarterPoints`,
        id,
        focusOnModalCloseRef
      )
    );
  };

  return (
    <Card className="mars-card--hover-reveal">
      <div
        className="mars-saved-insight-starter-point"
        tabIndex="0"
        ref={focusOnModalCloseRef}>
        <button
          className="mars-saved-insight-starter-point__save-btn mars-card__save-btn mars-button--svg"
          onClick={onActionBtnClick}
          aria-label="Save">
          <MarsIcon icon="trash" className="mars-card__trash-icon" />
          <MarsIcon icon="save" className="mars-card__save-icon" />
        </button>
        <p className="mars-body-small mars-text-colour--text-meta">
          {momentTitle}
        </p>
        {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
        <div
          className={`mars-saved-insight-starter-point__text mars-body-large mars-text-colour--brand1`}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </Card>
  );
};

export default SavedSavedInsightStarterPoint;

SavedSavedInsightStarterPoint.propTypes = {
  id: PropTypes.string.isRequired,
  momentTitle: PropTypes.string,
  text: PropTypes.string.isRequired
};
