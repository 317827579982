import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCollectionOwner from '@helpers/useCollectionOwner';
import actions from '@state/actions';
import Modal from '@components/molecules/Modal/Modal';
import ShareContentModal from '@components/molecules/ShareContentModal/ShareContentModal';
import DeleteCollectionModal from '@components/molecules/DeleteCollectionModal/DeleteCollectionModal';

const CollectionHero = ({ shareURL }) => {
  const dispatch = useDispatch();
  const collection = useSelector((state) => state.collections.collection);

  const [isShareOpen, setIsShareOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [collectionTitle, setCollectionTitle] = useState(``);
  const [collectionDescription, setCollectionDescription] = useState(``);

  const isCollectionOwner = useCollectionOwner(collection.id);

  const collectionTitleRef = useRef(null);
  const collectionDescriptionRef = useRef(null);
  const shareBtnRef = useRef();
  const editBtnRef = useRef();

  const deleteBtn = useRef(null);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const deleteOpen = () => setDeleteIsOpen(true);
  const deleteClose = () => {
    setDeleteIsOpen(false);
    deleteBtn.current.focus();
  };

  const deleteComplete = () => {
    // Change route to my collection page after deletion of current collection.
    window.location.href = `/my-collections`;
  };

  useEffect(() => {
    setCollectionTitle(collection.title);
    setCollectionDescription(collection.description);
  }, [collection]);

  const openShare = () => setIsShareOpen(true);
  const closeShare = () => {
    setIsShareOpen(false);
    shareBtnRef.current.focus();
  };

  const openEdit = () => setIsEditOpen(true);
  const closeEdit = () => {
    setIsEditOpen(false);
    editBtnRef.current.focus();
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      actions.editCollection({
        id: collection.id,
        title: collectionTitleRef.current.value,
        description: collectionDescriptionRef.current.value
      })
    );
    closeEdit();
  };

  return (
    <>
      <header className="mars-collection-hero">
        <div className="mars-container">
          <div className="mars-collection-hero__content mars-padding-top--xl mars-padding-bottom--xxl">
            <h2 className="mars-zeta mars-text-colour--text-meta mars-margin-bottom--xs">
              My Collections
            </h2>
            <h1 className="mars-alpha mars-margin-bottom--xs mars-text-colour--brand1">
              {collection.title}
            </h1>
            {collection.description && (
              <p className="mars-collection-hero__description mars-body-large mars-margin-bottom--md">
                {collection.description}
              </p>
            )}
            <div>
              <button
                className="mars-button"
                onClick={openShare}
                ref={shareBtnRef}>
                Share collection
              </button>
              {isCollectionOwner && (
                <>
                  <button
                    className="mars-button mars-button--secondary mars-margin-left--md"
                    onClick={openEdit}
                    ref={editBtnRef}>
                    <span className="mars-button__text">Edit collection</span>
                  </button>
                  <button 
                    ref={deleteBtn} 
                    onClick={deleteOpen} 
                    className="mars-button mars-button--secondary mars-margin-left--md">
                    <span className="mars-button__text">Delete collection</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      <ShareContentModal
        isOpen={isShareOpen}
        onDismiss={closeShare}
        shareURL={shareURL}
      />

      <Modal isOpen={isEditOpen} onDismiss={closeEdit}>
        <div className="mars-text--center">
          <h2 className="mars-gamma">Edit Collection</h2>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="mars-form-row">
            <label className="mars-zeta" htmlFor="collection-title">
              Collection Title
            </label>
            <input
              ref={collectionTitleRef}
              required
              value={collectionTitle}
              className="mars-text-input"
              onChange={(e) => setCollectionTitle(e.target.value)}
            />
          </div>
          <div className="mars-form-row">
            <label className="mars-zeta" htmlFor="collection-notes">
              Edit Notes
            </label>
            <textarea
              ref={collectionDescriptionRef}
              placeholder="Description of the collection goes here with any notes or comments"
              value={collectionDescription}
              className="mars-text-input"
              onChange={(e) => setCollectionDescription(e.target.value)}
            />
          </div>
          <div className="mars-form-row">
            <input
              type="submit"
              className="mars-button mars-button--wide"
              value="Save changes"
            />
          </div>
          <div className="mars-form-row">
            <button className="mars-button mars-button--secondary mars-button--wide">
              <span
                className="mars-button__text"
                onClick={() => setIsEditOpen(false)}>
                Cancel
              </span>
            </button>
          </div>
        </form>
      </Modal>
      <DeleteCollectionModal
        isOpen={deleteIsOpen}
        onDismiss={deleteClose}
        onDelete={deleteComplete}
        collection={collection}
      />
    </>
  );
};

export default CollectionHero;
