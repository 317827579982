export default ({ id, title, url, image, stage, lifestage }) => ({
  id,
  title,
  url,
  image: {
    path: image,
    alt: title
  },
  stageTitle: stage,
  lifestage
});
