import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '@state/actions';
import useCollectionOwner from '@helpers/useCollectionOwner';
import queryString from 'query-string';
import classnames from 'classnames';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import CollectionHero from '@components/organisms/CollectionHero/CollectionHero';
import Insight from '@components/molecules/Insight/Insight';
import SavedInsightStarterPoint from '@components/organisms/SavedInsightStarterPoint/SavedInsightStarterPoint';
import InsightModal from '@components/molecules/InsightModal/InsightModal';
import SnippetCardList from '@components/organisms/SnippetCardList/SnippetCardList';
import MomentCard from '@components/molecules/MomentCard/MomentCard';
import NoSavedItems from '@components/atoms/NoSavedItems/NoSavedItems';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

import './collection.scss';

import munger from '@state/services/collections/collection.munger';

const Collection = ({ location }) => {
  const dispatch = useDispatch();
  const { id } = queryString.parse(location.search);
  const collection = useSelector((state) => state.collections.collection);
  const isCollectionOwner = useCollectionOwner(id);

  let contentSnippets = [];
  let snippets = [];
  if (collection) {
    contentSnippets = collection.contentSnippets.map(
      ({ id, type, content }) => ({
        id,
        category: type,
        description: content
      })
    );
    snippets = [...contentSnippets, ...collection.dataSnippets];
  }

  useEffect(() => {
    dispatch(actions.getCollection(id));

    return function cleanup() {
      dispatch(actions.clearCollection());
    };
  }, [location]);

  return (
    <Layout location={location} title="Collection">
      <SEO title="Collection" />
      {collection && (
        <div
          className={classnames(`mars-collection-page`, {
            'mars-collection-page--is-owner': isCollectionOwner
          })}>
          <GTMPageLoad
            {...{
              page_type: `collection`,
              page_taxonomy_field: `Collection`,
              page_name: collection.title
            }}
          />
          <CollectionHero shareURL={location.href} />

          <div className="mars-container">
            {isCollectionOwner ||
            (collection.insights && collection.insights.length) ? (
              <section className="mars-margin-top--xl">
                <h2 className="mars-gamma">
                  {isCollectionOwner ? `My insights` : `Insights`}
                </h2>
                <div className="mars-snippet-card-list mars-cols mars-cols--3-cols">
                  {collection.insights &&
                    collection.insights.map((insight, i) => (
                      <div
                        key={`insight${i}`}
                        className="mars-margin-bottom--md">
                        <Insight id={insight.id} text={insight.text} />
                      </div>
                    ))}
                  {isCollectionOwner && <InsightModal collectionId={id} />}
                </div>
              </section>
            ) : null}

            {isCollectionOwner ||
            (collection.insightStarterPoints &&
              collection.insightStarterPoints.length) ? (
              <section className="mars-margin-top--xxl">
                <h2 className="mars-gamma">Saved insight starter points</h2>
                {collection.insightStarterPoints &&
                  !collection.insightStarterPoints.length && (
                    <NoSavedItems title="No insight starter points saved yet!" />
                  )}
                <div className="mars-grid mars-grid--2-cols">
                  {collection.insightStarterPoints &&
                    collection.insightStarterPoints.map((insight, i) => (
                      <SavedInsightStarterPoint
                        key={`starterpoint${i}`}
                        id={insight.id}
                        momentTitle={insight.momentTitle}
                        text={insight.content}
                      />
                    ))}
                </div>
              </section>
            ) : null}

            {isCollectionOwner || (snippets && snippets.length) ? (
              <section className="mars-margin-top--xxl">
                <h2 className="mars-gamma">Saved items</h2>
                {snippets && !snippets.length && (
                  <NoSavedItems title="No items saved yet!" />
                )}
                <SnippetCardList location={location} results={snippets} />
              </section>
            ) : null}

            {isCollectionOwner ||
            (collection.moments && collection.moments.length) ? (
              <section className="mars-margin-y--xxl mars-collection-page--moments">
                <h2 className="mars-gamma">Saved moments</h2>
                {collection.moments && !collection.moments.length && (
                  <NoSavedItems title="No moments saved yet!" />
                )}
                <div className="mars-grid mars-grid--4-cols">
                  {collection.moments &&
                    collection.moments.map((moment, i) => {
                      const data = munger(moment);
                      return <MomentCard key={`moment${i}`} {...data} />;
                    })}
                </div>
              </section>
            ) : null}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Collection;
