import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TextareaWithCounter.scss';

const TextareaWithCounter = ({
  maxTextLength = 100,
  onTextChange,
  labelText,
  textValue = ``,
  placeholder = ``
}) => {
  const [charactersUsed, setCharactersUsed] = useState(`low`);
  const [currentText, setCurrentText] = useState(textValue);

  const handleTextChange = (currentText) => {
    onTextChange(currentText);
    setCurrentText(currentText);

    if (currentText.length > maxTextLength) {
      if (onTextChange && typeof onTextChange === `function`) {
        onTextChange(currentText);
      }
      return setCharactersUsed(`error`);
    }

    if (currentText.length > maxTextLength * 0.7) {
      return setCharactersUsed(`medium`);
    }

    setCharactersUsed(``);
  };

  return (
    <div className="mars-textarea-with-counter">
      <label className="mars-textarea-with-counter__label">
        {labelText && (
          <span className="mars-zeta mars-textarea-with-counter__label-text">
            {labelText}
          </span>
        )}
        <div
          className={classnames(`mars-textarea-with-counter__inner`, {
            'mars-textarea-with-counter__inner--character-error':
              currentText.length > maxTextLength
          })}>
          <textarea
            className="mars-textarea-with-counter__textarea"
            required
            placeholder={placeholder}
            value={currentText}
            onChange={(e) => handleTextChange(e.target.value)}
          />
          <span
            className={`mars-textarea-with-counter__number mars-textarea-with-counter--${charactersUsed}`}>
            {currentText.length} / {maxTextLength}
            {charactersUsed === `error` && ` - Character limit reached!`}
          </span>
        </div>
      </label>
    </div>
  );
};

export default TextareaWithCounter;

TextareaWithCounter.propTypes = {
  maxTextLength: PropTypes.number,
  onTextChange: PropTypes.func,
  labelText: PropTypes.string,
  textValue: PropTypes.string,
  placeholder: PropTypes.string
};
