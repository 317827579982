import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import Card from '@components/atoms/Card/Card';
import Modal from '@components/molecules/Modal/Modal';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './Insight.scss';

const Insight = ({ id, text }) => {
  const dispatch = useDispatch();
  const focusOnModalCloseRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => {
    setIsOpen(false);
    if (typeof focusOnModalCloseRef !== `undefined`) {
      focusOnModalCloseRef.current.focus();
    }
  };

  const removeInsight = () => {
    dispatch(actions.removeInsight(id));
    closeDialog();
  };

  return (
    <>
      <Card className="mars-card--hover-reveal">
        <div className="mars-insight" tabIndex="0" ref={focusOnModalCloseRef}>
          <button
            className="mars-insight__save-btn mars-card__save-btn mars-button--svg"
            onClick={openDialog}
            aria-label="Remove from collection">
            <MarsIcon icon="trash" className="mars-card__trash-icon" />
            <MarsIcon icon="save" className="mars-card__save-icon" />
          </button>
          {/* The value is used with dangerouslySetInnerHTML to preserve the HTML markup. */}
          <span
            className="mars-body-large mars-text-colour--brand1"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </Card>

      <Modal
        isOpen={isOpen}
        onDismiss={closeDialog}
        className="mars-modal--dialog mars-text--center">
        <p className="mars-body">
          Are you sure you want to remove this item from your collection?
        </p>
        <div className="mars-form-row">
          <button
            className="mars-button mars-button--wide"
            onClick={removeInsight}>
            Yes, remove
          </button>
        </div>
        <button className="mars-button mars-button--secondary mars-button--wide">
          <span className="mars-button__text" onClick={closeDialog}>
            Cancel
          </span>
        </button>
      </Modal>
    </>
  );
};

export default Insight;

Insight.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};
